<template>
    <div class="container">
        <FilterDashboard @filter="onFilter" @filterStart="onFilterStart" @filterEnd="onFilterEnd"
            @filterStatus="onFilterStatus" @filterNivel="onFilterNivel" :showNivel="true" />

        <div style="text-align:right" class="chart">
            <div class="row">
                <div class="col-md-6">
                    <h5>Vacantes por KPI</h5>

                    <h6>Total de vacantes: {{ total }}</h6>

                </div>
                <div class="col-md-3">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button :class="{ active: activeBar }" type="button" class="btn btn-primary"
                            @click="changeChart('bar')">Barras</button>
                        <button :class="{ active: activePie }" type="button" class="btn btn-primary"
                            @click="changeChart('donut')">Pastel</button>
                    </div>
                </div>
            </div>

        </div>
        <div style="display: flex; justify-content: center; align-items: center;">
            <div v-if="options.chart.type === 'bar'">
                <apexchart ref="chart" width="700" type="bar" :options="{ ...options }" :series="series"></apexchart>
            </div>
            <div v-else-if="options.chart.type === 'donut'">
                <apexchart ref="chart" width="700" type="donut" :options="pieOptions" :series="pieSeries"> </apexchart>
            </div>
        </div>

    </div>
</template>

<script>

import CompanyService from "../../services/company.service";
import VueApexCharts from 'vue3-apexcharts';
import FilterDashboard from "../filter/FilterDashboard.vue";

export default ({
    name: 'vacantes-kpi',
    components: { apexchart: VueApexCharts, FilterDashboard },
    data() {
        return {
            activeBar: true,
            activePie: false,
            series: [{
                name: '',
                data: []
            }],
            options: {
                chart: {
                    id: 'vuechart-kpi',
                    type: 'bar',
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'KPI'
                    },
                    labels: {
                        show: false
                    }
                },
                fill: {
                    opacity: 1,
                    colors: []
                },
                plotOptions: {
                    bar: {
                        columnWidth: '',
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                            enabled: true,
                            textAnchor: 'start',
                            style: {
                                colors: ['#fff']
                            },
                            formatter: function (val, opt) {
                                return opt.w.globals.labels[opt.dataPointIndex]
                            },
                            offsetX: 0,
                            dropShadow: {
                                enabled: true
                            }
                        },
                        states: {
                            normal: {
                                filter: {
                                    type: 'desaturate'
                                }
                            },
                            active: {
                                allowMultipleDataPointsSelection: true,
                                filter: {
                                    type: 'darken',
                                    value: 1
                                }
                            }
                        },
                        tooltip: {
                            x: {
                                show: false
                            },
                            y: {
                                title: {
                                    formatter: function (val, opts) {
                                        return opts.w.globals.labels[opts.dataPointIndex]
                                    }
                                }
                            }
                        },
                    }
                },
                legend: {
                    show: false
                },

            },
            pieSeries: [],
            pieOptions: {
                chart: {
                    type: 'donut',
                },

                labels: [],
                fill: {
                    opacity: 1,
                    colors: []
                },
                legend: {
                    show: false
                },
                tooltip: {
                    enabled: true,
                },
            },
            user: JSON.parse(localStorage.getItem("user")),
            niveles: [],
            filtered: {
                filterStart: "",
                filterEnd: "",
                filterStatus: "",
                filterNivel: "",
                userId: ""
            },
            total: 0,
            promedio: 0,
            totalDias: 0
        }
    },
    mounted() {
        this.filtered.userId = this.user._id
        this.getVacantesByKPIAndUserId(this.user._id);
    },
    methods: {
        async getVacantesByKPIAndUserId(userId) {
            try {
                this.onClear();

                this.total = 0;
                this.totalDias = 0;
                const response = await CompanyService.getVacantesByKPIAndUserId(userId);
                const { data } = response.data;

                this.promedio = data.totalDaysAvg;
                this.total = data.totalVacantes;
                this.totalDias = data.totalDaysSum;

                data.vacantes.map((item) => {

                    this.options.xaxis.categories.push(item.kpi.value);
                    this.pieOptions.labels.push(item.kpi.value);

                    if (item.kpi.value === 'ROJO') {
                        this.options.fill.colors.push('#FF0000');
                        this.pieOptions.fill.colors.push('#FF0000');
                    }
                    if (item.kpi.value === 'VERDE') {
                        this.options.fill.colors.push('#008000');
                        this.pieOptions.fill.colors.push('#008000');
                    }
                    if (item.kpi.value === 'AMARILLO') {
                        this.options.fill.colors.push('#f1c40f');
                        this.pieOptions.fill.colors.push('#f1c40f');
                    }
                });

                data.vacantes.map((item) => {
                    this.series[0].data.push(item.count);
                    this.pieSeries.push(item.count);
                });

                let countColumns = this.options.xaxis.categories.length;
                var optimalColumnWidthPercent = 15 + (60 / (1 + 30 * Math.exp(-countColumns / 3)));


                this.options.plotOptions.bar = {
                    ...this.options.plotOptions.bar,
                    columnWidth: (optimalColumnWidthPercent + '%').toString()
                };

                this.$refs.chart.updateOptions(this.options)

            } catch (e) {
                console.log(e);
            }

        },
        async getVacantesByKPIFilter() {

            try {
                this.onClear();

                this.total = 0;
                this.totalDias = 0;
                const response = await CompanyService.getVacantesByKPIFilter(this.filtered);
                const { data } = response.data;

                this.promedio = data.totalDaysAvg;
                this.total = data.totalVacantes;
                this.totalDias = data.totalDaysSum;

                data.vacantes.map((item) => {

                    this.options.xaxis.categories.push(item.kpi.value);
                    this.pieOptions.labels.push(item.kpi.value);

                    if (item.kpi.value === 'ROJO') {
                        this.options.fill.colors.push('#FF0000');
                        this.pieOptions.fill.colors.push('#FF0000');
                    }
                    if (item.kpi.value === 'VERDE') {
                        this.options.fill.colors.push('#008000');
                        this.pieOptions.fill.colors.push('#008000');
                    }
                    if (item.kpi.value === 'AMARILLO') {
                        this.options.fill.colors.push('#f1c40f');
                        this.pieOptions.fill.colors.push('#f1c40f');
                    }

                });

                data.vacantes.map((item) => {
                    this.series[0].data.push(item.count);
                    this.pieSeries.push(item.count);
                });

                let countColumns = this.options.xaxis.categories.length;
                var optimalColumnWidthPercent = 15 + (60 / (1 + 30 * Math.exp(-countColumns / 3)));


                this.options.plotOptions.bar = {
                    ...this.options.plotOptions.bar,
                    columnWidth: (optimalColumnWidthPercent + '%').toString()
                };

                this.$refs.chart.updateOptions(this.options)

            } catch (e) {
                console.log(e);
            }
        },

        onFilter(filter) {
            console.log('filter =>', filter);
            this.filtered.filter = filter;
            this.getVacantesByKPIFilter();
        },
        onFilterStart(filter) {
            console.log('filterDate =>', filter);
            this.filtered.filterStart = filter;
        },
        onFilterEnd(filter) {
            console.log('filterDate =>', filter);
            this.filtered.filterEnd = filter;
            this.getVacantesByKPIFilter();
        },
        onFilterStatus(filter) {
            console.log('filterStatus=>', filter);
            this.filtered.filterStatus = filter;
            this.getVacantesByKPIFilter();
        },
        onFilterNivel(filter) {
            console.log('filterNivel=>', filter);
            this.filtered.filterNivel = filter;
            this.getVacantesByKPIFilter();
        },
        changeChart(type) {
            if (type === 'bar') {
                this.options.chart.type = 'bar';
            } else if (type === 'donut') {
                this.options.chart.type = 'donut';
            }
            this.toggleActive();
            this.$refs.chart.updateOptions(this.options); // Asegura la actualización del gráfico
        },

        toggleActive() {
            this.activeBar = !this.activeBar;
            this.activePie = !this.activePie;
        },
        onClear() {

            this.options.xaxis = { ... this.options.xaxis, categories: [] };
            this.series[0] = { ...this.series[0], data: [] };
            this.options.fill.colors = [];

            this.pieOptions = { ...this.pieOptions, labels: [] };
            this.pieSeries = [];
        },
    }
})
</script>

<style>
.chart {
    padding-top: 3%;
}
</style>
